import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>
      This is a page for BudgetMeister app. Currently it only includes the
      privacy policy which you can find <Link to="/privacy/policy">here</Link>
    </p>
  </Layout>
)

export default IndexPage
